import './App.css';
import ResultCard from './components/ResultCard/ResultCard.js';
import Header from './components/Header/Header.js';
import {useState} from 'react';


function App() {

  return (
    <div className="App">
      <Header/>
      <div className='results'>
        <div className='container'>
          <div style={{backgroundColor:"white", padding:20, borderRadius:10}}>
            <h2>Welcome to Panda Docs!</h2>
            <p>You can use this website to find awesome tutorials and stuff, try one of the following queries!</p><br/>
            <a href='/search/jorssen%20conversies'>🔎 Jorssen conversies</a><br/>
            <a href='/search/combell'>🔎 Combell</a><br/>
            <a href='/search/facebook%20toegang'>🔎 Facebook toegang</a><br/>
            <a href='/search/website%20checklist'>🔎 Website checklist</a><br/>
            <a href='/search/checklist'>🔎 Checklist</a><br/>
            <a href='/search/growth%20hacks'>🔎 Growth Hacks Database</a><br/>
            <a href='/search/purple%20panda%20bedrijfsgegevens'>🔎 Panda bedrijfs gegevens</a><br/>
            <a href='/search/panda%20handbook'>🔎 Panda Employee Handbook</a><br/>

            <br/><br/><br/>
          </div>
          

        </div>
      </div>
    </div>
  );
}

export default App;
