import { useParams, useNavigate } from "react-router-dom";
import Header from '../components/Header/Header.js';
import {useState} from 'react';
import ResultCard from '../components/ResultCard/ResultCard.js';
import HighlightCard from '../components/HighlightCard/HighlightCard.js';


export default function SearchResults() {

    const {searchQuery} = useParams();
    const [searchResults, setSearchResults] = useState([]); 
    const [searchedFlag, setSearchedFlag] = useState(false);
    const [uiToShow, setUIToShow] = useState("loader"); 
    const navigate = useNavigate();

    const [highlightedCard, setHighlightedCard] = useState({}); 
    const [viewState, setViewState] = useState("hidden"); 
    const [value, setValue] = useState(0);
    

    const searchItems = () => {
      fetch("https://functions.purplepanda.be/guru-api-proxy/main.php?action=search&q="+encodeURIComponent(searchQuery), {
        method: "GET",
        headers: {
          Accept: "application/json"
        },
      }).then(response => response.json())
      .then(data => {
        setUIToShow("");
        if(data.length == 0){
          setUIToShow("no-results");
        }
        setSearchResults(data);
        console.log(data);
      });
    }

    
    
    /*const handleCardClick = (r) => {
      console.log(r);
      //
      navigate("/card/"+ r.slug);
      window.location.reload(false);
    }*/



    const handleCardClick = (r) => {
      window.scrollTo(0, 0);
      setHighlightedCard(r);
      setViewState("highlight-active");
      console.log(r);
      window.history.pushState("/card/"+r.slug, "");
      window.history.replaceState(null, "New Page Title", "/card/"+r.slug)
      setValue(value => value + 1); // force refresh
    }

    if(!searchedFlag){
      searchItems();
      console.log('searching');
      setSearchedFlag(true);
    }

    window.history.replaceState(null, "", "")

    console.log(window.location.pathname);

  return (
    <div>
      {window.location.pathname.includes('/card/') && 
        <div>
          <div className="highlight-card-header">
            <div className="container" style={{marginTop:0, marginBottom:0}}>
                <div className='back-button' onClick={() => navigate(-1)}>
                 &#60; Back
                </div>
            </div>
            
          </div>
          <HighlightCard resultObject={highlightedCard} viewState={viewState} closePopup={() => setViewState('')} />
        </div>
      }
      {!window.location.pathname.includes('/card/') && 
        <div>
          <Header searchQuery={searchQuery} />
          <div className='results'>
            <div className='container'>
              {uiToShow == "loader" && 
              <div>
                <div style={{marginBottom:20}}>Loading results for: <span>{searchQuery}</span> ...</div>
                <img id="loader" src="/giphy.gif"/>
              </div>
              }
              {uiToShow == "no-results" && 
              <div>
                <div style={{marginBottom:20}}>No results for: <span>{searchQuery}</span></div>
                <img id="loader" src="/no-results.gif"/>
              </div>}
    
              {uiToShow == "" && <div style={{marginBottom:20}}>Results for: <span>{searchQuery}</span></div>}
    
              {searchResults.map((result, i) => {     
                // Return the element. Also pass key     
                return (
                  <div onClick = {() => handleCardClick(result)}>
                    <ResultCard 
                      resultObject = {result}
                    />
                  </div>
                  ) 
              })}
            </div>
          </div>
        </div>
      }
      
      
    </div>
  );
}
