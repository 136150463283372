import { useParams } from "react-router-dom";
import Header from '../components/Header/Header.js';
import HighlightCard from '../components/HighlightCard/HighlightCard.js';
import {useState} from 'react';

export default function Card() {

    const {cardId} = useParams();
    const {cardSlug} = useParams();

    const [searchedFlag, setSearchedFlag] = useState(false);
    const [uiToShow, setUIToShow] = useState("loader"); 
    const [cardResult, setCardResult] = useState(false); 


    const getCard = () => {
      fetch("https://functions.purplepanda.be/guru-api-proxy/main.php?action=getCard&cardId="+encodeURIComponent(cardId), {
        method: "GET",
        headers: {
          Accept: "application/json"
        },
      }).then(response => response.json())
      .then(data => {
        setUIToShow("");

        setCardResult(data);
        console.log(data);
      });
    }

    if(!searchedFlag){
      getCard();
      console.log('searching');
      setSearchedFlag(true);
    }

  return (
    <div id="card">
      <Header />
      <div className='results'>
        <div className='container'>
        {cardResult === false && <img id="loader" src="/giphy.gif"/>}
        {cardResult !== false &&
            <HighlightCard resultObject={cardResult}  />
        }
        <br/><br/><br/>
        This is a card! {cardId} | {cardSlug}
        </div>
      </div>

    </div>
  );
}
