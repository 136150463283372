import React from 'react';
import './ResultCard.css';


function ResultCard(props) {
  function createMarkup(str) {
    return {__html: str};
  }
  
  //format some data
  const resultObject = props.resultObject;

  var cardTitle = resultObject.preferredPhrase;
  if(resultObject.highlightedTitleContent.length !== 0){
    cardTitle = resultObject.highlightedTitleContent;
  }
  
  const cardSummary = resultObject.highlightedBodyContent[0];
 

  return (
    <div className='result-card'>
      <div className='body'>
        <h2 className='title' dangerouslySetInnerHTML={createMarkup(cardTitle)}></h2>
        <div className='summary' dangerouslySetInnerHTML={createMarkup(cardSummary)}></div>
      </div>
    </div>
  );
}

export default ResultCard;