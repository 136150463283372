import React from 'react';
import './Header.css';
import { Navigate, useLocation,useNavigate } from "react-router-dom";
import {useState} from 'react';


function Header(props) {
 
  const navigate = useNavigate();

  const handleKeyUpSearch = (e) => {
    if(e.code == "Enter"){
        //setSearchQuery(e.target.value);
        navigate("/search/"+ e.target.value);
        window.location.reload(false);

    }
  }




    return (
      <div className='header'>
      <div className='container'>
        <a href='/' id='logo-link'><h1>🧠 PandaDocs</h1></a>
        <h2>Tutorials and content from Agency of the year 2025</h2>
        <div className='search-container'>
          <input id='searchbar' placeholder='Search and press enter' onKeyUp={handleKeyUpSearch}/>
        </div>
      </div>
    </div>
    );
  

  
}

export default Header;