import React from 'react';
import './HighlightCard.css';


function HighlightCard(props) {
  function createMarkup(str) {
    return {__html: str};
  }
 
  //format some data
  const resultObject = props.resultObject;
  var cardTitle = resultObject.preferredPhrase;

  var collectionName = "";
  if(props.resultObject.collection != undefined){
    collectionName = props.resultObject.collection.name;
  }

  var ownerName = "";
  var ownerProfileImg = "";
  if(props.resultObject.owner != undefined){
    ownerName = props.resultObject.owner.firstName + " " + props.resultObject.owner.lastName;
    ownerProfileImg = props.resultObject.owner.profilePicUrl;
  }

  return (
    <div id="highlight-card" className={props.viewState}>
        
        
        <div id="highlight-card-inner" >
            <div className="container">
                <div className='body'>

                    <h1 className='title' dangerouslySetInnerHTML={createMarkup(cardTitle)}></h1>

                    <div className='meta-data'>
                        
                        <div>
                            <div>created by <b>{ownerName}</b></div>
                            <div>from collection <b>{collectionName} </b></div>
                            <br/>
                        </div>
                        
                        
                        <div className='actions' style={{textAlign:"right"}}>
                            <a href={'https://app.getguru.com/card/' +  resultObject.slug } target="_blank">Open in Guru</a><br/>
                            
                            <div id="verification-state" className={resultObject.verificationState}>
                            {resultObject.verificationState === "NEEDS_VERIFICATION" && <label>Needs verification</label>}
                            {resultObject.verificationState === "TRUSTED" && <label>Verified</label>}
                            {resultObject.publicLinkAllowed && <label style={{color: "#717376"}}> | public</label>}
                            
                            </div>
                        </div>
                    </div>
                    
                    <br/>
                    <div className='summary' dangerouslySetInnerHTML={createMarkup(resultObject.content)}></div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default HighlightCard;